const ApiKey = () => {
    return (
        {
            "SERVICE_ID": "service_xdft0mt",
            "USER_ID": "user_WBiwvERttqhAIXuabdlFy",
            "TEMPLATE_ID": "template_c51latb" 
        }
    )
}

export default ApiKey